body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
button {
  cursor: pointer;
  user-select: none;
}
button:disabled {
  pointer-events: none;
}
h1 {
  margin: 0px;
}
h2 {
  margin: 0px;
}
*:focus {
  outline: none;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Spinner Styles */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Placeholder Styles */
::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: solid 4px transparent;
  background-clip: padding-box;
}

/* Text Styles */
.headline1 {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 124.3%;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.6);
}
.headline2 {
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 119.5%;
  letter-spacing: -0.005em;
  color: rgba(0, 0, 0, 0.6);
}
.headline3 {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 133%;
  color: rgba(0, 0, 0, 0.6);
}
.headline4 {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 135%;
  letter-spacing: 0.0025em;
  color: rgba(0, 0, 0, 0.6);
}
.headline5 {
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 135%;
  color: rgba(0, 0, 0, 0.6);
}
.headline6 {
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  letter-spacing: 0.015em;
  color: rgba(0, 0, 0, 0.6);
}
.subtitle1 {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 123.19%;
  letter-spacing: 0.0015em;
  color: rgba(0, 0, 0, 0.6);
}
.subtitle2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 115%;
  letter-spacing: 0.001em;
  color: rgba(0, 0, 0, 0.6);
}
.bodyText1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 123.19%;
  letter-spacing: 0.005em;
  color: rgba(0, 0, 0, 0.6);
}
.bodyText2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: rgba(0, 0, 0, 0.6);
}
.buttonText {
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 115%;
  letter-spacing: 0.0125em;
}
.caption {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.6);
}
.overline {
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
}
.chartTitle {
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.015em;
  color: rgba(0, 0, 0, 0.6);
}
.chartSubtitle {
  font-family: Barlow Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: rgba(0, 0, 0, 0.6);
}
.chartAxisLabel {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.6);
}
.chartDataLabel {
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.004em;
  color: rgba(0, 0, 0, 0.6);
}
.chartCallout {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: 0.004em;
  color: rgba(0, 0, 0, 0.6);
}

/* Text Color Styles */
.--primaryHighText {
  color: #0a3d62;
}
.--primaryMediumText {
  color: #007ab5;
}
.--primaryLowText {
  color: #cceaff;
}
.--darkHighText {
  color: rgba(0, 0, 0, 0.87);
}
.--darkMediumText {
  color: rgba(0, 0, 0, 0.6);
}
.--darkLowText {
  color: rgba(0, 0, 0, 0.38);
}
.--lightText {
  color: #ffffff;
}

/* Font Weight Styles */
.--boldText {
  font-weight: bold;
}

/* Button Styles */
.button {
  position: relative;
}
.button .--small {
  padding: 5px 15px;
}
.button .--medium {
  padding: 7px 15px;
}
.button .--large {
  padding: 9px 15px;
}
.button .--default {
  overflow: hidden;
  white-space: nowrap;
  border-radius: 4px;
}
.button .--primary {
  border: 1px solid #007ab5;
  background-color: #007ab5;
  color: #ffffff;
}
.button .--primary:hover {
  background-color: #0070a7;
}
.button .--primary:focus {
  background-color: #005c89;
}
.button .--primary:active {
  background-color: #00537b;
}
.button .--primary:disabled {
  color: rgba(0, 0, 0, 0.38);
  background-color: #d2d2d2;
}
.button .--secondary {
  border: 1px solid #007ab5;
  background-color: inherit;
  color: #007ab5;
}
.button .--secondary:hover {
  background-color: #e6ebee;
}
.button .--secondary:focus {
  background-color: #d5e3ed;
}
.button .--secondary:active {
  background-color: #d5e3ed;
}
.button .--secondary:disabled {
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid #f7f7f7;
  background-color: transparent;
}
.button .--action {
  border: 1px solid transparent;
  background-color: transparent;
  color: #ffffff;
}
.button .--textOnly {
  border: 1px solid transparent;
  background-color: transparent;
  color: #007ab5;
}
.button .--textOnly:hover {
  background-color: #e6ebee;
}
.button .--textOnly:focus {
  background-color: #d5e3ed;
}
.button .--textOnly:active {
  background-color: #d5e3ed;
}
.button .--textOnly:disabled {
  color: rgba(0, 0, 0, 0.38);
  background-color: transparent;
}
.button .--icon {
  height: 18px;
  width: 18px;
}
.button .--tooltip {
  z-index: 2;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  white-space: nowrap;
  top: -28px;
}

/* Pill Styles */
.pillSwitch .--default {
  border-radius: 16px;
  padding: 6px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
}
.pillSwitch .--selected {
  border-radius: 16px;
  padding: 6px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #edf6fd;
}
.pillSelect {
  min-width: 0px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 6px 12px;
  cursor: default;
}

/* Snackbar Styles */
.snackbar {
  user-select: none;
  position: fixed;
  z-index: 4;
}
.snackbar.top-middle {
  top: 24px;
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  animation: toast-in-top 0.1s;
}
.snackbar.bottom-middle {
  bottom: 24px;
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  animation: toast-in-bottom 0.1s;
}
.snackbar.top-right {
  top: 24px;
  right: 24px;
  animation: toast-in-right 0.1s;
}
.snackbar.bottom-right {
  bottom: 24px;
  right: 24px;
  animation: toast-in-right 0.1s;
}
.snackbar.top-left {
  top: 24px;
  left: 24px;
  animation: toast-in-left 0.1s;
}
.snackbar.bottom-left {
  bottom: 24px;
  left: 24px;
  animation: toast-in-left 0.1s;
}
.snackbar__notification {
  transition: 0.1s ease;
  cursor: default;
  position: relative;
  overflow: hidden;
  padding: 16px;
  margin-bottom: 16px;
  width: 344px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.snackbar .--primary {
  background-color: #0a3d62;
}
.snackbar .--error {
  background-color: #b00020;
}
@keyframes toast-in-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    translate: translateY(0);
  }
}
@keyframes toast-in-top {
  from {
    transform: translate(-50%, -100%);
  }
  to {
    translate: translateY(0);
  }
}
@keyframes toast-in-right {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    translate: translateX(0);
  }
}
@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    translate: translateX(0);
  }
}

/* All Styles */
.bullet {
  min-width: 4px;
  min-height: 4px;
  margin-top: 8px;
  margin-right: 8px;
  border-radius: 2px;
  background-color: #0a3d62;
}
.textInput {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 4px 4px 0px 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.textInput:focus {
  background-image: none;
}
.textInput.--small {
  padding: 4px 8px;
}
.textInput.--large {
  padding: 8px 16px;
}
.textInput.--primary::placeholder {
  color: #007ab5;
}
.title {
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #000000;
}
.normalTitle {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #000000;
}
.subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #000000;
}
.regularText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
}
.smallBoldText {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #000000;
}
.mediumBoldText {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
}
.largeBoldText {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #000000;
}
.capitalText {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #696969;
}
.skipNav {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}
.skipNav:focus {
  clip: unset;
  height: 20px;
  margin: unset;
  white-space: unset;
  overflow: unset;
  width: 100%;
  background-color: #a70303;
  color: white;
  text-align: center;
  margin-left: 0;
  outline-width: 5px;
}
.analysis {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 51px);
}
.community {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 51px);
}
.account {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 51px);
}
.login {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 51px);
}
.newAnalysis {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 128px;
  flex: 1;
}
.legendBar {
  display: flex;
  flex-direction: column;
  position: sticky;
  background-color: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  top: 120px;
}
.recommendedModal {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.navBar {
  position: sticky;
  height: 51px;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.dropDownIcon {
  opacity: 0.6;
}
.regionMap {
  position: relative;
}
.regionMap__circle {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 24px;
  border: 1px solid #007ab5;
}
.squareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border-radius: 2px;
  height: 16px;
  width: 16px;
  padding: 0px;
  border: none;
}
.squareButton:hover {
  background-color: #e6ebee;
}
.loadingOverlay {
  user-select: none;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
}
.dash {
  min-width: 12px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.12);
}
.pipe {
  width: 2px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.loadingOverlay__icon {
  height: 140px;
  width: 140px;
}
.chartTooltip {
  z-index: 1;
  overflow: visible;
  user-select: none;
  position: absolute;
  width: 280px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.chartTooltip__section {
  display: flex;
  padding: 8px 0px;
  margin: 0px 8px;
}
.chartTooltip__icon {
  border-radius: 1px;
  margin-right: 8px;
}
.chartTooltip__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.quotientChip {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  border-radius: 2px;
  padding: 2px;
}
.navBar__title {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.navBar__tab {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 16px 24px;
  margin: 0px;
  background-color: inherit;
}
.navBar__dropdown {
  position: absolute;
  top: 50px;
  left: 50%;
  right: auto;
  width: 152px;
  background-color: #ffffff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;
  padding: 8px 8px 0px;
  transform: translate(-50%, 0);
}
.navBar__item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 8px;
  margin-bottom: 8px;
  background-color: inherit;
  border-radius: 4px;
  width: 100%;
}
.navBar__item:hover {
  background-color: rgba(22, 140, 225, 0.08);
}
.chartLegend__label {
  display: flex;
  align-items: stretch;
  white-space: pre-line;
  padding: 4px;
}
.chartLegend__icon {
  border-radius: 2px;
  margin-right: 4px;
}
.analysis__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 24px;
}
.analysis__content {
  padding: 16px 16px 16px 24px;
}
.analysis__body {
  flex: 1;
  display: grid;
  grid-template-columns: 360px auto;
  background-color: #f7f7f7;
}
.analysis__navButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 110px;
  height: 49px;
  margin: 0px 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  background-color: inherit;
}
.analysis__navButton:hover {
  background-color: #e6ebee;
}
.analysis__switchButton {
  background-color: #ffffff;
  position: absolute;
  bottom: -15px;
}
.analysis__dialog {
  background: #f7f7f7;
  border-radius: 4px;
  padding: 16px;
}
.analysis__chartBody {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100vh - 122px);
  padding: 0px 16px 24px 24px;
}
.analysis__chartSection {
  padding-top: 24px;
  padding-bottom: 160px;
}
.analysis__chartRow {
  display: grid;
  margin-bottom: 16px;
  grid-column-gap: 16px;
  grid-template-columns: 160px 1fr;
}
.analysis__chartCard {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
}
.analysis__chartSubheader {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
.analysis__chartRightColumn {
  margin-top: 56px;
  margin-left: 24px;
  max-width: 224px;
}
.analysis__chartFilter {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.account__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
}
.account__settingsCard {
  width: 400px;
  padding: 25px;
  border: 1px solid #c4c4c4;
  background-color: #f2f2f2;
}
.account__cardHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.account__cardItem {
  padding-top: 25px;
}
.account__fieldInput {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  background-color: #ffffff;
  border: 1px solid #808080;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #000000;
}
.account__option {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.account__regionList {
  background-color: #f2f2f2;
  border: 1px solid #808080;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  height: 200px;
  margin-top: 10px;
}
.account__regionListItem {
  padding: 12px;
}
.account__regionListItem:hover {
  background-color: #c4c4c4;
}
.account__regionSpace {
  height: 210px;
}
.account__buttonRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.account__logOutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 151px;
  height: 28px;
  background-color: inherit;
  border: none;
  border-radius: 2px;
}
.account__logOutButton:hover {
  background-color: #c4c4c4;
}
.account__error {
  display: flex;
  margin-top: 8px;
}
.account__errorText {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #e80000;
}
.account__logOutText {
  margin-right: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #656565;
}
.newAnalysis__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  max-width: 1200px;
  padding: 24px 0px;
}
.newAnalysis__content {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
}
.newAnalysis__card {
  height: 160px;
  padding: 16px;
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.legendBar__swapText {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #656565;
}
.legendBar__pillColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 8px;
}
.legendBar__plottedItem {
  padding: 1px;
  cursor: default;
}
.legendBar__plottedItem:hover {
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.legendBar__marker {
  border-left: 2px solid #c4c4c4;
  padding: 6px 7px;
}
.legendBar__marker:hover {
  border-left: 2px solid #808080;
}
.legendBar__markerMore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 4px;
}
.legendBar__wideIcon {
  width: 16px;
  height: auto;
}
.legendBar__tallIcon {
  width: auto;
  height: 16px;
}
.legendBar__markerIcon {
  width: 16px;
  height: 16px;
}
.legendBar__plottedIcon {
  min-width: 16px;
  min-height: 16px;
  border-radius: 2px;
}
.modal__header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0a3d62;
  padding: 10px;
}
.swapModal__body {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 15px;
  padding: 15px;
  max-height: 400px;
  width: 560px;
  overflow-y: scroll;
}
.swapModal__item {
  cursor: default;
  padding: 1px;
}
.swapModal__item:hover {
  border: none;
  border-radius: 4px;
  background-color: #f6fafe;
}
.swapModal__itemRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 2px solid #c4c4c4;
  padding: 2px 7px;
}
.swapModal__itemRow:hover {
  border-left: 2px solid #808080;
}
.swapModal__itemTitleRow {
  display: flex;
  align-items: center;
  padding: 6px 0px;
  margin-right: 10px;
}
.filterModal__list {
  overflow-y: scroll;
  height: calc(95vh - 304px);
}
.listItem {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 20px;
  cursor: pointer;
  user-select: none;
}
.listItem:hover {
  background-color: rgba(22, 140, 225, 0.04);
}
.searchModal__buttonRow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.searchModal__subheader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.searchModal__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.searchModal__lensButton {
  display: flex;
  align-items: center;
  padding: 14px;
  background-color: #f2f2f2;
  border: 1px solid #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
}
.searchModal__choiceRow {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 40px;
  margin-bottom: 4px;
}
.searchModal__choiceTab {
  width: 100%;
  height: 100%;
  border: none;
  padding: none;
  background-color: inherit;
}
.searchModal__choiceTab:hover {
  background-color: rgba(22, 140, 225, 0.04);
}
.searchModal__listItemPreview {
  padding: 12px 15px;
}
.searchModal__taxonomySelect {
  height: 100%;
  padding: 16px 32px 0px;
}
.searchModal__taxonomyRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding-top: 16px;
}
.searchModal__taxonomyCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.searchModal__taxonomyPoint {
  display: flex;
  align-items: flex-start;
  padding-bottom: 8px;
}
.searchModal__taxonomyHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
}
.searchModal__body {
  display: grid;
  grid-template-columns: 272px auto;
  grid-column-gap: 24px;
  padding-top: 16px;
}
.searchModal__listHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 54px;
  padding-right: 16px;
  padding-bottom: 8px;
}
.searchModal__checkboxRow {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  user-select: none;
}
.searchModal__checkboxRow:hover {
  background-color: rgba(22, 140, 225, 0.04);
}
.searchModal__peer {
  margin-top: 8px;
  margin-left: 34px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.searchModal__peerRow {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 24px;
}
.searchModal__suggestionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 16px;
}
.searchModal__card {
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  display: grid;
  grid-row-gap: 8px;
  cursor: default;
  user-select: none;
}
.searchModal__card.--single {
  grid-template-rows: 1fr 3fr;
}
.searchModal__card.--single:hover {
  background-color: rgba(22, 140, 225, 0.04);
}
.searchModal__card.--multi {
  grid-template-rows: auto 1fr;
}
.searchModal__cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
}
.searchModal__suggestion {
  display: flex;
  align-items: center;
  padding: 4px;
  user-select: none;
}
.searchModal__pillRow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 40px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.searchModal__regionBody {
  display: grid;
  grid-template-columns: auto;
}
.searchModal__lensTitleRow {
  padding-top: 16px;
  padding-left: 54px;
  padding-right: 32px;
  padding-bottom: 8px;
}
.searchModal__lensViewRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}
.searchModal__listScore {
  text-align: right;
  width: 100px;
}
.searchModal__lensArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  height: 24px;
  width: 24px;
}
.recommendedModal__card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 9px;
  margin: 8px;
}
.recommendedModal__card:hover {
  background-color: #f2f2f2;
  padding: 10px;
  border: none;
}
.recommendedModal__cardTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #656565;
}
.recommendedModal__cardSubtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #656565;
}
.recommendedModal__pillRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.recommendedModal__importButton {
  position: absolute;
  display: flex;
  align-items: center;
  right: 16px;
  top: 16px;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 7px;
}
.recommendedModal__buttonText {
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #656565;
}
.community__searchLink {
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 40px;
}
.community__searchLink:hover {
  background-color: #c4c4c4;
}
.community__searchForm {
  position: sticky;
  top: 50px;
  background-color: #ffffff;
  z-index: 1;
  padding: 40px 65px 0px;
}
.community__subheader {
  display: flex;
  flex-direction: column;
  margin: 0px 65px;
}
.community__section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c4c4c4;
  padding: 40px 0px;
}
.community__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.community__carousel {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  padding: 30px 65px 0px;
}
.community__scoreCard {
  cursor: pointer;
  padding: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: inherit;
}
.community__scoreCard:hover {
  border: 1px solid #656565;
  background-color: #c4c4c4;
}
.community__cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.community__scorePreview {
  cursor: pointer;
  border: none;
  padding: 0px;
}
.community__scorePreview:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.community__previewText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #000000;
}
.community__thumbnail {
  background-color: #d3d3d3;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 150px;
}
.community__largeBar {
  background-color: #d3d3d3;
  height: 16px;
  width: 100%;
}
.community__mediumBar {
  background-color: #d3d3d3;
  height: 8px;
  width: 100%;
}
.community__smallBar {
  background-color: #d3d3d3;
  height: 4px;
  width: 100%;
}
.community__progressBar {
  background-color: #828282;
  height: 100%;
}
.community__carouselLeft {
  transform: rotate(180deg);
  position: absolute;
  top: 150px;
  left: 15px;
}
.community__carouselRight {
  position: absolute;
  top: 150px;
  right: 15px;
}
.community__arrowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  border-radius: 17px;
  background-color: #f2f2f2;
  border: 1px solid #c4c4c4;
  padding: 0px;
}
.community__arrowButton:hover:enabled {
  background-color: #c4c4c4;
}
.community__arrowButton:disabled {
  opacity: 0.5;
}
.community__dashboardPreview {
  background-color: #f2f2f2;
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 20px;
}
.community__previewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 15px;
}
.community__previewBody {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.community__richPreview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
}
.community__similarityBox {
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-top: 10px;
  padding: 12px;
}
.community__previewItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.community__search {
  padding: 40px 100px 0px;
}
.community__profileHeader {
  position: sticky;
  top: 50px;
  background-color: #ffffff;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 40px;
  padding: 0px 20px;
  border-bottom: 1px solid #c4c4c4;
}
.community__body {
  display: grid;
  grid-template-columns: 250px auto;
}
.community__report {
  padding: 20px;
}
.community__sideBar {
  position: sticky;
  top: 120px;
  z-index: 1;
  height: auto;
}
.community__nav {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 0px 8px 8px 0px;
  padding: 10px 0px;
}
.community__navLink {
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 20px;
}
.community__navLink:hover {
  background-color: #f2f2f2;
}
.community__legend {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 0px 8px 8px 0px;
  margin-top: 20px;
  padding: 20px;
}
.community__legendItem {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.community__reportSection {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  margin-bottom: 20px;
}
.community__reportSubsection {
  padding: 20px;
  border-top: 1px solid #656565;
}
.community__reportBody {
  margin: 0px 20px;
  padding: 20px 0px;
  border-top: 1px solid #c4c4c4;
}
.community__reportContent {
  padding-top: 30px;
}
.community__rowValue {
  padding-left: 15px;
}
.community__columnValue {
  padding-top: 15px;
}
.community__twoRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.community__threeRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.community__fourRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.community__lqTableRow {
  display: grid;
  grid-template-columns: auto 100px 100px 100px;
  padding: 5px 30px;
}
.community__lqTableTopRow {
  display: grid;
  direction: rtl;
  text-align: center;
  grid-template-columns: 100px 100px 100px;
  padding: 5px 30px;
}
.community__tableWrapper {
  padding: 0px 100px;
}
.community__table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
.community__tableRow {
  text-align: left;
  border-bottom: 1px solid #828282;
}
.community__tableHeader {
  padding: 10px;
}
.community__tableCell {
  padding: 10px;
  border-left: 1px solid #828282;
  border-right: 1px solid #828282;
}
.community__peerTopRow {
  display: grid;
  direction: rtl;
  text-align: center;
  align-items: center;
  grid-template-columns: 50px 100px 100px 100px 100px 150px;
  padding: 0px 20px;
}
.community__peerRow {
  display: grid;
  align-items: center;
  grid-template-columns: 50px auto 100px 100px 100px 100px 50px;
  background-color: inherit;
  cursor: pointer;
}
.community__peerRow:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.community__peerLink:hover {
  text-decoration: underline;
}
.community__borderedRow {
  border-bottom: 1px solid #c4c4c4;
}
.community__socioeconomicCard {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-top: 15px;
}
.community__socioeconomicRow {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.community__socioeconomicRow:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.community__socioeconomicTable {
  padding: 0px 15px 15px;
}
.home__subheader {
  padding: 16px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.home__body {
  background-color: #f7f7f7;
  padding: 16px 24px;
}
.home__titleRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 8px;
}
.home__search {
  position: relative;
  width: 45%;
}
.home__dropdown {
  position: absolute;
  background-color: #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
  z-index: 1;
}
.home__listItem {
  user-select: none;
  cursor: pointer;
  padding: 16px;
}
.home__listItem:hover {
  background-color: rgba(22, 140, 225, 0.08);
}
.home__section {
  display: flex;
  align-items: stretch;
  max-width: 1320px;
  padding: 16px 0px;
  margin: auto;
}
.home__demographicSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: stretch;
  grid-gap: 16px;
  max-width: 1320px;
  margin: auto;
}
.home__demographicCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.home__trendCard {
  flex: 1 1 50%;
  align-self: stretch;
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
  max-width: 620px;
  background-color: #ffffff;
}
.home__industryCard {
  flex: 1 1 50%;
  align-self: stretch;
  margin-left: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #ffffff;
}
.home__industryRow {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 2.5fr;
  grid-gap: 8px;
  padding: 8px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.home__industryRow.--top {
  background-color: #f7f7f7;
  border: none;
}
.home__descriptionCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-height: 320px;
  max-width: 320px;
}
.helpToolTip {
  margin: 0px 8px;
  overflow: visible;
  position: relative;
  user-select: none;
  cursor: pointer;
}
.helpToolTip .popup {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  text-align: left;
  min-width: 240px;
  padding: 4px;
  z-index: 99;
}
.helpToolTip .popup.--left {
  right: 0;
}
.helpToolTip .popup > * {
  margin: 10px;
}
.home__peerItem {
  flex: 1;
  margin-left: 16px;
  display: grid;
  grid-template-rows: 1fr 0.5fr;
}
.home__peerCard {
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.home__peerCard:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.home__peerTooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 4px 8px;
  white-space: nowrap;
  transform: translate(-50%, 0);
  left: 50%;
  right: auto;
  bottom: -12px;
}
.home__peerPlaceholder {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cceaff;
}
.home__peerThumbnail {
  padding: 0px 16px 16px 16px;
}
.home__peerIcon {
  margin: 28px;
}
.home__peerScore {
  padding: 4px 8px;
  background-color: #ffffff;
  border-radius: 2px;
}
.home__analysisCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
  margin-left: 16px;
  flex: 1;
}
.home__analysisArea {
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: 8px;
  margin: 24px 0px;
}
.button--plain {
  background-color: inherit;
  border-radius: 2px;
  border: none;
  padding: none;
}
.button--plain:hover {
  background-color: #c4c4c4;
}
.button--primary {
  width: 200px;
  min-height: 30px;
  background-color: #c4c4c4;
  border: 1px solid #808080;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
}
.button--secondary {
  width: 200px;
  min-height: 30px;
  margin-right: 20px;
  background-color: inherit;
  border: none;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
}
.button--primary:hover {
  background-color: #808080;
}
.button--secondary:hover {
  background-color: #808080;
}

/* Overlay Styles */
.--hover {
  background-color: rgba(22, 140, 225, 0.04);
}
.--selected {
  background-color: rgba(22, 140, 225, 0.08);
}
.--focus {
  background-color: rgba(22, 140, 225, 0.12);
}
.--fullScreen {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Modifiers */
.--ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.--disabled {
  pointer-events: none;
}
.--cursorPointer {
  cursor: pointer;
  user-select: none;
}
.--cursorDefault {
  cursor: default;
  user-select: none;
}
.--heightFull {
  height: 100%;
}
.--widthFull {
  width: 100%;
}
.--block {
  display: block;
}
.--overlay {
  background-color: rgba(0, 0, 0, 0.25);
}
.--basicStroke {
  background-color: #c4c4c4;
}
.--basicDark {
  background-color: #808080;
}
.--left {
  left: 0px;
}
.--right {
  right: 0px;
}
.--floatLeft {
  float: left;
}
.--floatRight {
  float: right;
}
.--paddingNone {
  padding: 0px;
}
.--padding1 {
  padding: 8px;
}
.--padding2 {
  padding: 16px;
}
.--padding3 {
  padding: 24px;
}
.--paddingTop {
  padding-top: 8px;
}
.--paddingLeft {
  padding-left: 8px;
}
.--paddingRight {
  padding-right: 8px;
}
.--paddingBottom {
  padding-bottom: 8px;
}
.--paddingTop2 {
  padding-top: 16px;
}
.--paddingLeft2 {
  padding-left: 16px;
}
.--paddingRight2 {
  padding-right: 16px;
}
.--paddingBottom2 {
  padding-bottom: 16px;
}
.--paddingTop3 {
  padding-top: 24px;
}
.--paddingLeft3 {
  padding-left: 24px;
}
.--paddingRight3 {
  padding-right: 24px;
}
.--paddingBottom3 {
  padding-bottom: 24px;
}
.--marginNone {
  margin: 0px;
}
.--marginTop {
  margin-top: 8px;
}
.--marginLeft {
  margin-left: 8px;
}
.--marginRight {
  margin-right: 8px;
}
.--marginBottom {
  margin-bottom: 8px;
}
.--marginTop2 {
  margin-top: 16px;
}
.--marginLeft2 {
  margin-left: 16px;
}
.--marginRight2 {
  margin-right: 16px;
}
.--marginBottom2 {
  margin-bottom: 16px;
}
.--marginTop3 {
  margin-top: 24px;
}
.--marginLeft3 {
  margin-left: 24px;
}
.--marginRight3 {
  margin-right: 24px;
}
.--marginBottom3 {
  margin-bottom: 24px;
}
.--relative {
  position: relative;
}
.--absolute {
  position: absolute;
}
.--flex {
  display: flex;
}
.--flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.--flexGrow {
  flex: 1;
}
.--flexEnd {
  display: flex;
  justify-content: flex-end;
}
.--column {
  display: flex;
  flex-direction: column;
}
.--justifyCenter {
  display: flex;
  justify-content: center;
}
.--alignCenter {
  display: flex;
  align-items: center;
}
.--alignBaseline {
  display: flex;
  align-items: baseline;
}
.--alignEnd {
  display: flex;
  align-items: flex-end;
}
.--spaceBetween {
  display: flex;
  justify-content: space-between;
}
.--borderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.--selectedTab {
  border-bottom: 2px solid #0a3d62;
}
.--rotate180 {
  transform: rotate(180deg);
}
.--errorField {
  border-color: #e80000;
}
.--textAlignLeft {
  text-align: left;
}
.--textAlignRight {
  text-align: right;
}
.--textAlignCenter {
  text-align: center;
}
